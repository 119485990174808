import React, { useRef, useState } from 'react';
import api from '~/services/api';
import history from '~/services/history';

export default function Importar() {
  const [file, setFile] = useState(null);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const inputRef = useRef(null);

  const handleFileChange = event => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async event => {
    setError('');
    event.preventDefault();
    setLoading(true);

    if (!file) {
      setSuccess('Selecione um arquivo CSV antes de enviar.');
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('csv_file', file);

    try {
      const response = await api.post('assinatura/importCSV', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setSuccess(response.data.message);
      setTimeout(() => {
        history.push(`${process.env.PUBLIC_URL}/assinaturas/listar`);
      }, 2000);
    } catch (err) {
      setError(`Erro: ${err.response.data.message}`);
    } finally {
      setLoading(false);
      inputRef.current.value = null;
    }
  };

  return (
    <section>
      <div className="container-fluid">
        <div className="row d-flex">
          <div className="col-lg-6">
            <div className="card income p-3">
              <h3 className="box-title">Importar assinaturas</h3>
              <form onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="csv_file" className="control-label">
                    Selecione um arquivo CSV:
                  </label>
                  <div className="mb-2">
                    <input
                      className="form-control"
                      type="file"
                      id="csv_file"
                      name="csv_file"
                      accept=".csv"
                      onChange={handleFileChange}
                      ref={inputRef}
                    />
                  </div>
                  <small>
                    O arquivo deve ser no formato .csv e deve seguir o seguinte
                    padrão:
                  </small>
                  <div className="alert alert-secondary mw-100">
                    <p className="mb-0 font-italic">
                      NmNome,NmCpf
                      <br />
                      Nome do Assinante,012.345.678-90 <br />
                      Nome do Assinante,012.345.678-90
                    </p>
                  </div>
                </div>
                <button
                  className="btn btn-primary pull-right"
                  type="submit"
                  disabled={loading || !file}
                >
                  {loading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Importando...</span>
                    </>
                  ) : (
                    <span>Enviar</span>
                  )}
                </button>
              </form>
              {success && (
                <div className="alert alert-success" role="alert">
                  {success}
                </div>
              )}
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
