import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import Swal from 'sweetalert';
import api from '~/services/api';
import history from '~/services/history';

export default function Pesquisar() {
  const [page, setPage] = useState(1);
  const [assinaturas, setAssinaturas] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [searchedValue, setSearchedValue] = useState('');
  const [loadingPagination, setLoadingPagination] = useState(false);
  const [show, setShow] = useState(false);

  const [id, setId] = useState(0);

  const [assinaturaName, setAssinaturaName] = useState('');

  function handleChangeSearch(event) {
    setInputValue(event.target.value);
  }

  async function handleFetchSignatures(name, pageNumber) {
    setLoadingPagination(true);
    try {
      const response = await api.get(
        `assinatura/searchByNome?nome=${name}&page=${pageNumber}`
      );
      setAssinaturas(response.data.result);
      setPage(response.data.page);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.log(error);
    } finally {
      setSearchedValue(inputValue);
      setLoadingPagination(false);
    }
  }

  async function handleSubmit() {
    setPage(1);
    handleFetchSignatures(inputValue, 1);
  }
  async function handleNextPage() {
    setPage(prev => prev + 1);
    handleFetchSignatures(searchedValue, page + 1);
  }

  function handlePrevPage() {
    if (page > 1) {
      setPage(page - 1);
      handleFetchSignatures(searchedValue, page - 1);
    }
  }

  async function handleChangePage(event) {
    const newPage = parseInt(event.target.value);
    if (!isNaN(newPage) && newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
      handleFetchSignatures(searchedValue, newPage);
    }
  }

  function handleShow(idAssinatura, nome) {
    setId(idAssinatura);
    setAssinaturaName(nome);
    setShow(true);
  }

  function handleClose() {
    setShow(false);
  }

  async function handleClickApagar() {
    const response = await api.get(`assinatura/delete/${id}`);
    if (response.data) {
      Swal({
        title: 'Sucesso!',
        text: 'assinatura apagada!',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(_ => {
        setAssinaturas(assinaturas.filter(assinatura => assinatura.Id !== id));
        setShow(false);
      });
    } else {
      Swal('Erro', 'Erro ao apagar a assinatura tente novamente.', 'error');
    }

    setPage(1);
    setInputValue('');
    setAssinaturas([]);
    history.push('/assinaturas/pesquisar');
  }

  if (loadingPagination) {
    return (
      <section className="mt-5">
        <div className="container-fluid">
          <div className="row d-flex">
            <div className="col-lg-12">
              <div className="card income p-3">
                <div className="d-flex align-items-center justify-content-between">
                  <h3 className="box-title mb-20 pb-0">Pesquisar</h3>
                </div>
                <div>
                  <div className="form-group text-left col-md-4 p-0">
                    <label htmlFor="filter">Buscar por nome</label>
                    <div className="d-flex">
                      <input
                        type="text"
                        value={inputValue}
                        onChange={handleChangeSearch}
                        className="form-control"
                      />
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled={inputValue.length === 0}
                        onClick={handleSubmit}
                      >
                        Filtrar
                      </button>
                    </div>
                  </div>

                  {searchedValue && (
                    <div className="mb-4">
                      {' '}
                      Você buscou por: <strong>{searchedValue}</strong>
                    </div>
                  )}
                </div>

                <div
                  className="text-center p-4 d-flex flex-column align-items-center justify-content-center"
                  style={{ height: 'calc(100vh - 450px)', overflow: 'auto' }}
                >
                  <span
                    className="spinner-border spinner-border-sm text-secondary"
                    role="status"
                    aria-hidden="true"
                    style={{ width: '3rem', height: '3rem' }}
                  />
                  <span className="sr-only">Carregando registros...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="mt-5">
      <div className="container-fluid">
        <div className="row d-flex">
          <div className="col-lg-12">
            <div className="card income p-3">
              <div className="d-flex align-items-center justify-content-between">
                <h3 className="box-title mb-20 pb-0">Pesquisar</h3>
              </div>
              <div>
                <div className="form-group text-left col-md-4 p-0">
                  <label htmlFor="filter">Buscar por nome</label>
                  <div className="d-flex">
                    <input
                      type="text"
                      value={inputValue}
                      onChange={handleChangeSearch}
                      className="form-control"
                    />
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={inputValue.length === 0}
                      onClick={handleSubmit}
                    >
                      Filtrar
                    </button>
                  </div>
                </div>

                {searchedValue && (
                  <div className="mb-4">
                    Você buscou por: <strong>{searchedValue}</strong>
                  </div>
                )}
              </div>

              <div
                className="text-center"
                style={{ height: 'calc(100vh - 450px)', overflow: 'auto' }}
              >
                {assinaturas.length > 0 ? (
                  <table className="display table table-striped dataTable border">
                    <thead className="text-left">
                      <tr>
                        <th>ID</th>
                        <th>Nome</th>
                        <th>CPF</th>
                        <th colSpan={2} className="text-right">
                          Ações
                        </th>
                        {/*  <th>E-mail</th>
                         */}
                      </tr>
                    </thead>

                    <tbody>
                      {assinaturas.map(assinatura => (
                        <tr key={assinatura.Id} className="text-left">
                          <td>{assinatura.Id}</td>
                          <td>{assinatura.NmNome}</td>
                          <td>
                            {!assinatura.NmCpf ? (
                              <span className="badge badge-danger">
                                CPF Não cadastrado
                              </span>
                            ) : (
                              assinatura.NmCpf
                            )}
                          </td>
                          <td className="text-right">
                            <Link
                              to={`${process.env.PUBLIC_URL}/assinaturas/editar/${assinatura.Id}`}
                            >
                              <i className="fa fa-pencil text-info" />
                            </Link>
                            <button
                              type="button"
                              className="border-0 bg-transparent outline-0 modalBtn"
                              onClick={() =>
                                handleShow(assinatura.Id, assinatura.NmNome)
                              }
                            >
                              <i className="fa fa-trash text-danger" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p className="px-2">
                    Faça a busca para obter a lista das assinaturas
                  </p>
                )}
              </div>
              {totalPages > 1 && (
                <div className="d-flex align-items-center justify-content-end my-4">
                  <div className="d-flex align-items-center">
                    <label className="mb-0 mr-2">Página</label>
                    <input
                      className="form-control mx-2"
                      type="number"
                      value={page}
                      onChange={handleChangePage}
                      min={1}
                      max={totalPages}
                    />
                    <span className="mr-1">de</span>
                    <strong> {totalPages}</strong>
                    <button
                      className="btn btn-default ml-2"
                      disabled={page === 1}
                      onClick={handlePrevPage}
                      type="button"
                    >
                      Anterior
                    </button>
                    <button
                      className="btn btn-default"
                      onClick={handleNextPage}
                      disabled={page === totalPages}
                      type="button"
                    >
                      Próximo
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Apagar assinatura: {assinaturaName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b>ID:</b> <span className="id">{id}</span> <br />
          <b>Nome:</b> <span className="nome">{assinaturaName}</span> <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="danger" onClick={handleClickApagar}>
            Apagar
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
}
