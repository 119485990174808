import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert';
import $ from 'jquery';

import api from '~/services/api';
import history from '~/services/history';

export default function Listar() {
  const [show, setShow] = useState(false);
  const [materias, setMaterias] = useState([]);
  const [id, setId] = useState(0);
  const [materiaTitle, setMateriaTitle] = useState('');

  useEffect(() => {
    async function loadMaterias() {
      const response = await api.get('materias');
      setMaterias(response.data);
    }

    loadMaterias();
  }, []);

  function handleClose() {
    setShow(false);
  }

  function handleShow(idMateria, materia) {
    setId(idMateria);
    setMateriaTitle(materia);
    setShow(true);
  }

  async function handleClickApagar() {
    const response = await api.get(`materia/delete/${id}`);
    if (response.data) {
      Swal({
        title: 'Sucesso!',
        text: 'Matéria apagada!',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(_ => {
        setMaterias(materias.filter(materia => materia.Id !== id));
        setShow(false);
      });
    } else {
      Swal('Erro', 'Erro ao apagar a matéria tente novamente.', 'error');
    }
  }

  async function handleToggle(idMateria, publicado) {
    const FlPublicado = publicado === '1' ? '0' : '1';
    const formData = new FormData();
    formData.append('FlPublicado', FlPublicado);
    const response = await api.post(`materia/publicar/${idMateria}`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
    if (response.data) {
      const classe = $(`#materia-${idMateria}`).attr('class');
      if (classe === 'fa fa-toggle-on text-success') {
        $(`#materia-${idMateria}`)
          .removeClass(classe)
          .addClass('fa fa-toggle-off text-danger');
      } else {
        $(`#materia-${idMateria}`)
          .removeClass(classe)
          .addClass('fa fa-toggle-on text-success');
      }
    }
  }

  return (
    <>
      <section className="mt-5">
        <div className="container-fluid">
          <div className="row d-flex">
            <div className="col-12">
              <div className="p-3 card income">
                <h3 className="box-title">Listar matérias</h3>

                <form className="form-horizontal" method="post">
                  <div className="box-body table-responsive">
                    {materias.length > 0 ? (
                      <table className="table display table-striped dataTable">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Titulo</th>
                            <th>Titulo Capa</th>
                            <th>Capa</th>
                            <th>Categoria</th>
                            <th>Criado por</th>
                            <th width="100" className="no-sort" />
                          </tr>
                        </thead>
                        <tfoot>
                          <tr>
                            <th>ID</th>
                            <th>Titulo</th>
                            <th>Titulo Capa</th>
                            <th>Capa</th>
                            <th>Categoria</th>
                            <th>Criado por</th>
                            <th width="100" className="no-sort" />
                          </tr>
                        </tfoot>
                        <tbody>
                          {materias.map(materia => (
                            <tr>
                              <td className="align-middle">{materia.Id}</td>
                              <td className="align-middle">
                                {materia.NmTitulo}
                              </td>
                              <td className="align-middle">
                                {materia.NmTituloCapa}
                              </td>
                              <td className="align-middle">
                                <img
                                  src={materia.urlImg}
                                  alt={`Capa ${materia.NmCategoria}`}
                                  height="32"
                                />
                              </td>
                              <td className="align-middle">
                                {materia.NmCategoria}
                              </td>
                              <td className="align-middle">{materia.NmNome}</td>
                              <td className="text-right align-middle">
                                <button
                                  type="button"
                                  className="bg-transparent border-0 outline-0 modalBtn"
                                  onClick={() =>
                                    handleToggle(
                                      materia.Id,
                                      materia.FlPublicado
                                    )
                                  }
                                >
                                  <i
                                    className={`fa fa-toggle-${
                                      materia.FlPublicado === '1'
                                        ? 'on text-success'
                                        : 'off text-danger'
                                    }`}
                                    id={`materia-${materia.Id}`}
                                  />
                                </button>
                                &nbsp;
                                <Link
                                  to={`${process.env.PUBLIC_URL}/materias/editar/${materia.Id}`}
                                >
                                  <i className="fa fa-pencil text-info" />
                                </Link>
                                &nbsp;
                                <button
                                  type="button"
                                  className="bg-transparent border-0 outline-0 modalBtn"
                                  onClick={() =>
                                    handleShow(materia.Id, materia.NmTitulo)
                                  }
                                >
                                  <i className="fa fa-trash text-danger" />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p className="px-2">
                        Sem matérias inseridas até o momento.
                      </p>
                    )}
                  </div>
                  <div className="box-footer d-flex justify-content-between">
                    <button
                      type="button"
                      onClick={() => history.goBack()}
                      className="btn btn-default"
                    >
                      Voltar
                    </button>
                    <Link
                      to={`${process.env.PUBLIC_URL}/materias/cadastrar`}
                      className="btn btn-primary pull-right"
                    >
                      Cadastrar
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Apagar matéria: {materiaTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b>ID:</b> <span className="id">{id}</span> <br />
          <b>Nome:</b> <span className="nome">{materiaTitle}</span> <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="danger" onClick={handleClickApagar}>
            Apagar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
