import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';
import history from '~/services/history';

import { signInSuccess, signFailure } from './actions';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;

    const formData = new URLSearchParams();
    formData.append('NmEmail', email);
    formData.append('CdSenha', password);

    const response = yield call(api.post, 'login', formData);

    if (response.data) {
      const { token, user } = response.data;
      api.defaults.headers.Authorization = `Barear ${token}`;
      yield put(signInSuccess(token, user));
      history.push(`${process.env.PUBLIC_URL}/home`);
    } else {
      const erro = 'error';
      throw erro;
    }
  } catch (error) {
    toast.error('Falha na autenticação verifique seus dados');
    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const { name, email, password } = payload;

    yield call(api.post, '/users', {
      name,
      email,
      password,
      provider: true,
    });

    history.push(`${process.env.PUBLIC_URL}/`);
  } catch (error) {
    toast.error('Falha no cadastro verifique seus dados!');
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Barear ${token}`;
  }
}

export function signOut() {
  history.push(`${process.env.PUBLIC_URL}/`);
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
