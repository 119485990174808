import axios from 'axios';

const api = axios.create({
  baseURL: 'https://convergenciabrasil.com.br/backend/',
  /*   
  baseURL: 'http://localhost:8080/convergencia-brasil/back/',
  baseURL: 'https://amzmp.com.br/cliente/convergencia-brasil-back/',
  baseURL: 'http://br1000.teste.website/~conv5081/backend/'
  */
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

export default api;
