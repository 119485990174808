import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Input } from '@rocketseat/unform';
import swal from 'sweetalert';
import * as Yup from 'yup';

import api from '~/services/api';
import history from '~/services/history';

const schema = Yup.object().shape({
  name: Yup.string().required('O nome é obrigatório'),
  email: Yup.string()
    .email('E-mail inválido')
    .required('O e-mail é obrigatório'),
  password: Yup.string(),
});

export default function Editar() {
  const params = useParams();
  const { id } = params;
  const [usuario, setUsuario] = useState([]);

  useEffect(() => {
    async function loadUsuario() {
      const response = await api.get(`user/show/${id}`);
      setUsuario({
        name: response.data.NmNome,
        email: response.data.NmEmail,
      });
    }
    loadUsuario();
  }, [id]);

  async function handleSubmit(data) {
    const formData = new URLSearchParams();
    formData.append('NmNome', data.name);
    formData.append('NmEmail', data.email);
    if (data.password) {
      formData.append('CdSenha', data.password);
    }

    const response = await api.post(`user/update/${id}`, formData);

    if (response.data) {
      swal(
        'Edição realizada com sucesso!',
        'A edição foi realizada com sucesso',
        'success'
      ).then(_ => {
        document.querySelector('#password').value = '';
      });
    } else {
      swal(
        'Erro na atualização!',
        'Ocorreu um erro no atualização tente novamente',
        'error'
      );
    }
  }

  return (
    <section>
      <div className="container-fluid">
        <div className="row d-flex">
          <div className="col-lg-6">
            <div className="card income p-3">
              <h3 className="box-title">Editar Usuário</h3>

              <Form
                schema={schema}
                initialData={usuario}
                onSubmit={handleSubmit}
                className="form-horizontal mb-2"
                id="formAdmin"
              >
                <div className="form-group">
                  <label htmlFor="categoria" className="col-sm-3 control-label">
                    Nome
                  </label>
                  <div className="col-sm-12">
                    <Input
                      className="form-control"
                      name="name"
                      placeholder="Nome"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="categoria" className="col-sm-3 control-label">
                    Login
                  </label>
                  <div className="col-sm-12">
                    <Input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="E-mail"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="categoria" className="col-sm-3 control-label">
                    Senha
                  </label>
                  <div className="col-sm-12">
                    <Input
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder="Senha"
                    />
                  </div>
                </div>

                <div className="box-footer mt-3 d-flex justify-content-between">
                  <button
                    type="button"
                    onClick={() => history.goBack()}
                    className="btn btn-default"
                  >
                    Voltar
                  </button>
                  <button type="submit" className="btn btn-primary pull-right">
                    Editar
                  </button>
                </div>
              </Form>

              <div className="alert w-100 display-none msg" role="alert" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
