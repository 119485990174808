import React, { useState, useEffect } from 'react';
import { Form, Input } from '@rocketseat/unform';
import swal from 'sweetalert';
import { useParams } from 'react-router-dom';

import api from '~/services/api';
import history from '~/services/history';

export default function Cadastrar() {
  const params = useParams();
  const { id } = params;
  const [categoria, setCategoria] = useState([]);

  useEffect(() => {
    async function loadCategoria() {
      const response = await api.get(`categoria/show/${id}`);
      setCategoria({ newCategoria: response.data.NmCategoria });
    }
    loadCategoria();
  }, [id]);

  async function handleSubmit({ newCategoria }) {
    const formData = new URLSearchParams();
    formData.append('NmCategoria', newCategoria);
    const response = await api.post(`categoria/update/${id}`, formData);

    if (response.data) {
      swal(
        'Edição realizada!',
        'A edição foi realizado com sucesso',
        'success'
      ).then(_ => {
        history.push(`${process.env.PUBLIC_URL}/categorias/listar`);
      });
    } else {
      swal(
        'Erro no cadastro!',
        'Ocorreu um erro no cadastro tente novamente',
        'error'
      );
    }
  }

  return (
    <section>
      <div className="container-fluid">
        <div className="row d-flex">
          <div className="col-lg-6">
            <div className="card income p-3">
              <h3 className="box-title">Editar categoria</h3>

              <Form
                initialData={categoria}
                onSubmit={handleSubmit}
                className="form-horizontal mb-2"
                id="formAdmin"
              >
                <div className="form-group">
                  <label htmlFor="categoria" className="col-sm-3 control-label">
                    Categoria
                  </label>
                  <div className="col-sm-9">
                    <Input
                      className="form-control"
                      name="newCategoria"
                      placeholder="Categoria"
                      required="required"
                    />
                    <small className="caracteres-nome">
                      <span className="caracteres">32</span> caracteres
                      restantes.
                    </small>
                  </div>
                </div>

                <div className="box-footer mt-3 d-flex justify-content-between">
                  <button
                    type="button"
                    onClick={() => history.goBack()}
                    className="btn btn-default"
                  >
                    Voltar
                  </button>
                  <button type="submit" className="btn btn-primary pull-right">
                    Editar
                  </button>
                </div>
              </Form>

              <div className="alert w-100 display-none msg" role="alert" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
