import React from 'react';
import { Switch } from 'react-router-dom';

import Login from '~/pages/Login';
import Home from '~/pages/Home';
import CategoriasCadastrar from '~/pages/Categorias/Cadastrar';
import CategoriasListar from '~/pages/Categorias/Listar';
import CategoriasEditar from '~/pages/Categorias/Editar';
import AssinaturasCadastrar from '~/pages/Assinaturas/Cadastrar';
import AssinaturasListar from '~/pages/Assinaturas/Listar';
import AssinaturasImportar from '~/pages/Assinaturas/Importar';
import AssinaturasEditar from '~/pages/Assinaturas/Editar';
import MateriasCadastrar from '~/pages/Materias/Cadastrar';
import MateriasListar from '~/pages/Materias/Listar';
import MateriasEditar from '~/pages/Materias/Editar';
import UsuariosCadastrar from '~/pages/Usuarios/Cadastrar';
import UsuariosListar from '~/pages/Usuarios/Listar';
import UsuariosEditar from '~/pages/Usuarios/Editar';
import Leads from '~/pages/Leads';
import Route from './Route';
import AssinaturasPesquisar from '~/pages/Assinaturas/Pesquisar';

export default function Router() {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={Login} />
      <Route
        path={`${process.env.PUBLIC_URL}/home`}
        component={Home}
        exact
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/categorias/cadastrar`}
        component={CategoriasCadastrar}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/categorias/listar`}
        component={CategoriasListar}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/categorias/editar/:id`}
        component={CategoriasEditar}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/assinaturas/cadastrar`}
        component={AssinaturasCadastrar}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/assinaturas/listar`}
        component={AssinaturasListar}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/assinaturas/pesquisar`}
        component={AssinaturasPesquisar}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/assinaturas/importar`}
        component={AssinaturasImportar}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/assinaturas/editar/:id`}
        component={AssinaturasEditar}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/materias/cadastrar`}
        component={MateriasCadastrar}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/materias/listar`}
        component={MateriasListar}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/materias/editar/:id`}
        component={MateriasEditar}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/usuarios/cadastrar`}
        component={UsuariosCadastrar}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/usuarios/listar`}
        component={UsuariosListar}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/usuarios/editar/:id`}
        component={UsuariosEditar}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/leads`}
        component={Leads}
        isPrivate
      />
    </Switch>
  );
}
