import React from 'react';

// import { Container } from './styles';

function Footer() {
  return (
    <footer className="main-footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6">
            <p>Convergência Brasil&copy; 2021</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
