import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert';

import api from '~/services/api';
import history from '~/services/history';

export default function Listar() {
  const [show, setShow] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [id, setId] = useState(0);
  const [usuarioName, setUsuarioName] = useState('');

  useEffect(() => {
    async function loadUsuarios() {
      const response = await api.get('users');
      setUsuarios(response.data);
    }

    loadUsuarios();
  }, []);

  function handleClose() {
    setShow(false);
  }

  function handleShow(idUsuario, usuario) {
    setId(idUsuario);
    setUsuarioName(usuario);
    setShow(true);
  }

  async function handleClickApagar() {
    const response = await api.get(`user/delete/${id}`);
    if (response.data) {
      Swal({
        title: 'Sucesso!',
        text: 'Usuário apagado!',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(_ => {
        setUsuarios(usuarios.filter(usuario => usuario.Id !== id));
        setShow(false);
      });
    } else {
      Swal('Erro', 'Erro ao apagar o usuário tente novamente.', 'error');
    }
  }

  return (
    <>
      <section className="mt-5">
        <div className="container-fluid">
          <div className="row d-flex">
            <div className="col-12">
              <div className="card income p-3">
                <h3 className="box-title">Listar usuários</h3>

                <form className="form-horizontal" method="post">
                  <div className="box-body table-responsive">
                    {usuarios.length > 0 ? (
                      <table className="display table table-striped dataTable">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Nome</th>
                            <th width="100" className="no-sort" />
                          </tr>
                        </thead>
                        <tfoot>
                          <tr>
                            <th>ID</th>
                            <th>Nome</th>
                            <th width="100" className="no-sort" />
                          </tr>
                        </tfoot>
                        <tbody>
                          {usuarios.map(usuario => (
                            <tr>
                              <td className="align-middle">{usuario.Id}</td>
                              <td className="align-middle">{usuario.NmNome}</td>
                              <td className="align-middle text-right">
                                <Link
                                  to={`${process.env.PUBLIC_URL}/usuarios/editar/${usuario.Id}`}
                                >
                                  <i className="fa fa-pencil text-info" />
                                </Link>
                                &nbsp;&nbsp;
                                <button
                                  type="button"
                                  className="border-0 bg-transparent outline-0 modalBtn"
                                  onClick={() =>
                                    handleShow(usuario.Id, usuario.NmNome)
                                  }
                                >
                                  <i className="fa fa-trash text-danger" />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p className="px-2">
                        Sem usuários inseridos até o momento.
                      </p>
                    )}
                  </div>
                  <div className="box-footer d-flex justify-content-between">
                    <button
                      type="button"
                      onClick={() => history.goBack()}
                      className="btn btn-default"
                    >
                      Voltar
                    </button>
                    <Link
                      to={`${process.env.PUBLIC_URL}/usuarios/cadastrar`}
                      className="btn btn-primary pull-right"
                    >
                      Cadastrar
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Apagar usuário: {usuarioName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b>ID:</b> <span className="id">{id}</span> <br />
          <b>Nome:</b> <span className="nome">{usuarioName}</span> <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="danger" onClick={handleClickApagar}>
            Apagar
          </Button>
        </Modal.Footer>
      </Modal>
      ;
    </>
  );
}
