import React from 'react';
import { Form, Input } from '@rocketseat/unform';
import swal from 'sweetalert';

import api from '~/services/api';
import history from '~/services/history';

export default function Cadastrar() {
  async function handleSubmit({ nome, /* email, */ cpf }) {
    const formData = new URLSearchParams();
    formData.append('NmNome', nome);
    /* formData.append('NmEmail', email); */
    formData.append('NmCpf', cpf);

    await api
      .post('assinatura/create', formData)
      .then(response => {
        swal(
          'Cadastro realizado!',
          'O cadastro foi realizado com sucesso',
          'success'
        ).then(_ => {
          history.push(`${process.env.PUBLIC_URL}/assinaturas/listar`);
        });
      })
      .catch(error => {
        swal({
          icon: 'error',
          title: 'Erro no cadastro!',
          text: `${error.response.data.erro.replace(/<\/?p>/g, '')}`,
        });
      });
  }

  return (
    <section>
      <div className="container-fluid">
        <div className="row d-flex">
          <div className="col-lg-6">
            <div className="card income p-3">
              <h3 className="box-title">Cadastrar Assinatura</h3>

              <Form
                onSubmit={handleSubmit}
                className="form-horizontal mb-2"
                id="formAdmin"
              >
                <div className="form-group">
                  <label htmlFor="nome" className="col-sm-3 control-label">
                    Nome
                  </label>
                  <div className="col-sm-9">
                    <Input
                      className="form-control"
                      name="nome"
                      placeholder="Nome"
                      required="required"
                    />
                  </div>
                </div>

                {/*  <div className="form-group">
                  <label htmlFor="email" className="col-sm-3 control-label">
                    E-mail
                  </label>
                  <div className="col-sm-9">
                    <Input
                      className="form-control"
                      name="email"
                      placeholder="E-mail"
                      required="required"
                    />
                  </div>
                </div> */}

                <div className="form-group">
                  <label htmlFor="cpf" className="col-sm-3 control-label">
                    CPF
                  </label>
                  <div className="col-sm-9">
                    <Input
                      className="form-control"
                      name="cpf"
                      placeholder="CPF"
                      required="required"
                    />
                  </div>
                </div>

                <div className="box-footer mt-3 d-flex justify-content-between">
                  <button
                    type="button"
                    onClick={() => history.goBack()}
                    className="btn btn-default"
                  >
                    Voltar
                  </button>
                  <button type="submit" className="btn btn-primary pull-right">
                    Cadastrar
                  </button>
                </div>
              </Form>

              <div className="alert w-100 display-none msg" role="alert" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
