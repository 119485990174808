import React, { useState, useEffect } from 'react';
import { Form, Input } from '@rocketseat/unform';
import swal from 'sweetalert';
import { useParams } from 'react-router-dom';

import api from '~/services/api';
import history from '~/services/history';

export default function Cadastrar() {
  const params = useParams();
  const { id } = params;
  const [assinatura, setAssinatura] = useState([]);

  useEffect(() => {
    async function loadAssinatura() {
      const response = await api.get(`assinatura/show/${id}`);
      setAssinatura({
        newNome: response.data.NmNome,
        newEmail: response.data.NmEmail,
        newCpf: response.data.NmCpf,
      });
    }
    loadAssinatura();
  }, [id]);

  async function handleSubmit({ newNome, /* newEmail, */ newCpf }) {
    const formData = new URLSearchParams();
    formData.append('NmNome', newNome);
    /*     formData.append('NmEmail', newEmail);
     */ formData.append('NmCpf', newCpf);
    const response = await api.post(`assinatura/update/${id}`, formData);

    if (response.data) {
      swal(
        'Edição realizada!',
        'A edição foi realizado com sucesso',
        'success'
      ).then(_ => {
        history.push(`${process.env.PUBLIC_URL}/assinaturas/listar`);
      });
    } else {
      swal(
        'Erro no cadastro!',
        'Ocorreu um erro no cadastro tente novamente',
        'error'
      );
    }
  }

  return (
    <section>
      <div className="container-fluid">
        <div className="row d-flex">
          <div className="col-lg-6">
            <div className="card income p-3">
              <h3 className="box-title">Editar categoria</h3>

              <Form
                initialData={assinatura}
                onSubmit={handleSubmit}
                className="form-horizontal mb-2"
                id="formAdmin"
              >
                <div className="form-group">
                  <label htmlFor="newNome" className="col-sm-3 control-label">
                    Nome
                  </label>
                  <div className="col-sm-9">
                    <Input
                      className="form-control"
                      name="newNome"
                      placeholder="Nome"
                      required="required"
                    />
                  </div>
                </div>

                {/*   <div className="form-group">
                  <label htmlFor="newEmail" className="col-sm-3 control-label">
                    E-mail
                  </label>
                  <div className="col-sm-9">
                    <Input
                      className="form-control"
                      name="newEmail"
                      placeholder="E-mail"
                      required="required"
                    />
                  </div>
                </div>
 */}
                <div className="form-group">
                  <label htmlFor="newCpf" className="col-sm-3 control-label">
                    CPF
                  </label>
                  <div className="col-sm-9">
                    <Input
                      className="form-control"
                      name="newCpf"
                      placeholder="CPF"
                      required="required"
                    />
                  </div>
                </div>

                <div className="box-footer mt-3 d-flex justify-content-between">
                  <button
                    type="button"
                    onClick={() => history.goBack()}
                    className="btn btn-default"
                  >
                    Voltar
                  </button>
                  <button type="submit" className="btn btn-primary pull-right">
                    Editar
                  </button>
                </div>
              </Form>

              <div className="alert w-100 display-none msg" role="alert" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
