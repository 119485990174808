import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import { signInRequest } from '~/store/modules/auth/actions';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('E-mail inválido')
    .required('O e-mail é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
});

export default function Login() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function handleSubmit() {
    dispatch(signInRequest(email, password));
  }

  return (
    <div className="page login-page">
      <div className="container">
        <div className="form-outer text-center d-flex align-items-center">
          <div className="form-inner">
            <div className="logo text-uppercase">
              <span>Painel</span>{' '}
              <strong className="text-primary">Administrativo</strong>
            </div>
            <p>
              Digite abaixo o login e senha para acessar o painel
              administrativo.
            </p>
            <Form
              schema={schema}
              onSubmit={handleSubmit}
              className="text-left form-validate"
            >
              <div className="form-group-material">
                <Input
                  name="email"
                  className={`input-material ${
                    email.length > 0 ? 'is-valid' : ''
                  }`}
                  onChange={e => setEmail(e.target.value)}
                  required
                />
                <label
                  htmlFor="email"
                  className={`label-material ${
                    email.length > 0 ? 'active' : ''
                  }`}
                >
                  E-mail
                </label>
              </div>
              <div className="form-group-material">
                <Input
                  type="password"
                  name="password"
                  className={`input-material ${
                    password.length > 0 ? 'is-valid' : ''
                  }`}
                  onChange={e => setPassword(e.target.value)}
                  required
                />
                <label
                  htmlFor="senha"
                  className={`label-material ${
                    password.length > 0 ? 'active' : ''
                  }`}
                >
                  Senha
                </label>
              </div>
              <div className="form-group text-center">
                <button type="submit" className="btn btn-primary">
                  Login
                </button>
              </div>
            </Form>

            {/* <a href="#" className="forgot-pass">
              Forgot Password?
            </a>
            <small>Do not have an account? </small>
            <a href="register.html" className="signup">
              Signup
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
}
