import React from 'react';
import { FaSignature } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function Home() {
  return (
    <>
      <section className="dashboard-counts section-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="p-4 card">
                <h1>Bem-vindo(a)!</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <div className="card income">
                <Link
                  to="/assinaturas/listar"
                  className="p-4 position-relative d-flex align-items-center text-bold "
                  style={{ gap: 4, overflow: 'hidden' }}
                >
                  <FaSignature
                    className="mr-1 fa-4x position-absolute text-secondary"
                    style={{
                      top: '-2px',
                      left: '-8px',
                      opacity: '20%',
                    }}
                  />
                  <h5 className="mb-0">Assinaturas</h5>
                </Link>
              </div>
            </div>
            <div className="col-md-2">
              <div className="card income">
                <Link
                  to="/categorias/listar"
                  className="p-4 position-relative d-flex align-items-center text-bold "
                  style={{ gap: 4, overflow: 'hidden' }}
                >
                  <i
                    className="fa fa-list fa-4x position-absolute text-secondary"
                    style={{
                      top: '-2px',
                      left: '-8px',
                      opacity: '20%',
                    }}
                  />
                  <h5 className="mb-0">Categorias</h5>
                </Link>
              </div>
            </div>
            <div className="col-md-2">
              <div className="card income">
                <Link
                  to="materias/listar"
                  className="p-4 position-relative d-flex align-items-center text-bold "
                  style={{ gap: 4, overflow: 'hidden' }}
                >
                  <i
                    className="fa fa-newspaper-o fa-4x position-absolute text-secondary"
                    style={{
                      top: '-2px',
                      left: '-8px',
                      opacity: '20%',
                    }}
                  />
                  <h5 className="mb-0">Matérias</h5>
                </Link>
              </div>
            </div>
            <div className="col-md-2">
              <div className="card income">
                <Link
                  to="/usuarios/listar"
                  className="p-4 position-relative d-flex align-items-center text-bold "
                  style={{ gap: 4, overflow: 'hidden' }}
                >
                  <i
                    className="fa fa-user fa-4x position-absolute text-secondary"
                    style={{
                      top: '-2px',
                      left: '-8px',
                      opacity: '20%',
                    }}
                  />
                  <h5 className="mb-0">Usuários</h5>
                </Link>
              </div>
            </div>
            <div className="col-md-2">
              <div className="card income">
                <Link
                  to="leads"
                  className="p-4 position-relative d-flex align-items-center text-bold "
                  style={{ gap: 4, overflow: 'hidden' }}
                >
                  <i
                    className="fa fa-envelope-o fa-4x position-absolute text-secondary"
                    style={{
                      top: '-2px',
                      left: '-8px',
                      opacity: '20%',
                    }}
                  />
                  <h5 className="mb-0">Leads</h5>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
