import React from 'react';
import PropsTypes from 'prop-types';

export default function Login({ children }) {
  return <>{children}</>;
}

Login.propTypes = {
  children: PropsTypes.element.isRequired,
};
