import React, { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';

import api from '~/services/api';
import history from '~/services/history';

export default function Leads() {
  const [leads, setLeads] = useState([]);

  useEffect(() => {
    async function loadLeads() {
      const response = await api.get('leads');
      const data = response.data.map(lead => {
        const NmLead = JSON.parse(lead.NmLead);
        return {
          id: lead.Id,
          name: NmLead.name,
          email: NmLead.email ? NmLead.email : null,
          celular: NmLead.celphone,
          cep: NmLead.cep ? NmLead.cep : null,
          cidade: NmLead.cidade ? NmLead.cidade : null,
          estado: NmLead.estado ? NmLead.estado : null,
          plataforma: NmLead.plataforma ? NmLead.plataforma : null,
          mensagem: NmLead.mensagem ? NmLead.mensagem : null,
          date: format(parseISO(lead.DtCreatedAt), 'dd/MM/yyyy'),
        };
      });
      setLeads(data);
    }

    loadLeads();
  }, []);

  return (
    <>
      <section className="mt-5">
        <div className="container-fluid">
          <div className="row d-flex">
            <div className="col-12">
              <div className="card income p-3">
                <h3 className="box-title">Leads</h3>

                <form className="form-horizontal" method="post">
                  <div className="box-body table-responsive">
                    {leads.length > 0 ? (
                      <table className="display table table-striped dataTable">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Lead</th>
                            <th>Data</th>
                          </tr>
                        </thead>
                        <tfoot>
                          <tr>
                            <th>ID</th>
                            <th>Lead</th>
                            <th>Data</th>
                          </tr>
                        </tfoot>
                        <tbody>
                          {leads.map(lead => (
                            <tr key={lead.Id}>
                              <td className="align-middle">{lead.id}</td>
                              <td className="align-middle">
                                <b>Nome: </b> {lead.name}
                                <br />
                                {lead.email ? (
                                  <>
                                    <b>Email: </b> {lead.email} <br />
                                  </>
                                ) : (
                                  ''
                                )}
                                <b>Celular: </b> {lead.celular}
                                <br />
                                {lead.cep ? (
                                  <>
                                    <b>CEP: </b> {lead.cep} <br />
                                  </>
                                ) : (
                                  ''
                                )}
                                {lead.estado ? (
                                  <>
                                    <b>Estado: </b> {lead.estado} <br />
                                  </>
                                ) : (
                                  ''
                                )}
                                {lead.cidade ? (
                                  <>
                                    <b>Cidade: </b> {lead.cidade} <br />
                                  </>
                                ) : (
                                  ''
                                )}
                                {lead.plataforma ? (
                                  <>
                                    <b>Plataforma que deseja investir: </b> {lead.plataforma} <br />
                                  </>
                                ) : (
                                  ''
                                )}
                                {lead.mensagem ? (
                                  <>
                                    <b>Mensagem: </b> {lead.mensagem} <br />
                                  </>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td className="align-middle">{lead.date}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p className="px-2">Sem leads até o momento.</p>
                    )}
                  </div>
                  <div className="box-footer d-flex justify-content-between">
                    <button
                      type="button"
                      onClick={() => history.goBack()}
                      className="btn btn-default"
                    >
                      Voltar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
