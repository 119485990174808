import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import $ from 'jquery';

import { signOut } from '~/store/modules/auth/actions';

function Header() {
  const dispatch = useDispatch();

  useEffect(() => {
    // eslint-disable-next-line func-names
    $('#toggle-btn').on('click', function(e) {
      e.preventDefault();

      if ($(window).outerWidth() > 1194) {
        $('nav.side-navbar').toggleClass('shrink');
        $('.page').toggleClass('active');
      } else {
        $('nav.side-navbar').toggleClass('show-sm');
        $('.page').toggleClass('active-sm');
      }
    });
  }, []);

  function handleLogout() {
    dispatch(signOut());
  }

  return (
    <header className="header">
      <nav className="navbar">
        <div className="container-fluid">
          <div className="navbar-holder d-flex align-items-center justify-content-between">
            <div className="navbar-header">
              <button
                type="button"
                id="toggle-btn"
                className="menu-btn border-0 outline-0"
              >
                <i className="icon-bars"> </i>
              </button>
            </div>
            <ul className="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">
              <li className="nav-item">
                <button
                  type="button"
                  className="bg-transparent border-0 nav-link logout text-white"
                  onClick={handleLogout}
                >
                  <span className="d-none d-sm-inline-block mr-1">Logout</span>
                  <i className="fa fa-sign-out" />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
