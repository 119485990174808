import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert';

import api from '~/services/api';
import history from '~/services/history';

export default function Listar() {
  const [show, setShow] = useState(false);
  const [assinaturas, setAssinaturas] = useState([]);
  const [id, setId] = useState(0);
  const [assinaturaName, setAssinaturaName] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingPagination, setLoadingPagination] = useState(false);
  const [totalAssinaturas, setTotalAssinaturas] = useState(0);
  const [totalDuplicado, setTotalDuplicado] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);

  async function loadTotalDuplicated() {
    try {
      const response = await api.get(`assinatura/totalDuplicado`);
      setTotalDuplicado(response.data.totalDuplicados);
    } catch (error) {
      setErrorMessage(error.data.error);
    }
  }

  async function loadAssinaturas(pageNumber) {
    try {
      const response = await api.get(`assinatura/listar?page=${pageNumber}`);
      setAssinaturas(response.data.assinaturas);
      setTotalAssinaturas(response.data.total);
      setPage(response.data.page);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingPagination(false);
      setLoading(false);
    }
  }

  useEffect(() => {
    loadAssinaturas(page);
    loadTotalDuplicated();
  }, [page]);

  function handleClose() {
    setShow(false);
  }

  function handleShow(idAssinatura, nome) {
    setId(idAssinatura);
    setAssinaturaName(nome);
    setShow(true);
  }

  async function handleClickApagar() {
    const response = await api.get(`assinatura/delete/${id}`);
    if (response.data) {
      Swal({
        title: 'Sucesso!',
        text: 'assinatura apagada!',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(_ => {
        setAssinaturas(assinaturas.filter(assinatura => assinatura.Id !== id));
        setShow(false);
      });
    } else {
      Swal('Erro', 'Erro ao apagar a assinatura tente novamente.', 'error');
    }
  }

  function handleNextPage() {
    setLoadingPagination(true);
    setPage(prev => prev + 1);
  }
  function handlePrevPage() {
    setLoadingPagination(true);
    setPage(prev => prev - 1);
  }

  async function handleChangePage(event) {
    setLoadingPagination(true);
    setPage(event.target.value);
  }

  async function removerDuplicados() {
    setLoading(true);
    try {
      await api.get('assinatura/removeDuplicates');
      Swal({
        icon: 'success',
        title: 'Registros apagados!',
        text: 'Clique em Ok recarregar a lista.',
      });
    } catch (error) {
      setErrorMessage(error.response.data.message);
    } finally {
      loadAssinaturas(page);
      loadTotalDuplicated();
      setLoading(false);
      history.push(`${process.env.PUBLIC_URL}/assinaturas/listar`);
    }
  }
  if (loading) {
    return (
      <section className="mt-5">
        <div className="container-fluid">
          <div className="row d-flex">
            <div className="col-lg-12">
              <div className="card income p-3">
                <div className="d-flex align-items-center justify-content-between">
                  <h3 className="box-title mb-0 pb-0">Listar Assinaturas</h3>
                  <div className="d-flex align-items-center">
                    <div className="alert-secondary alert mb-0 mr-2">
                      Total de assinaturas:
                      {totalAssinaturas === 0 ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <strong>{totalAssinaturas}</strong>
                      )}
                    </div>
                    <div className="alert-warning alert mb-0">
                      Assinaturas duplicadas:
                      {totalDuplicado === 0 ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <strong>{totalDuplicado}</strong>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="text-center p-4 d-flex flex-column align-items-center justify-content-center"
                  style={{ height: 'calc(100vh - 450px)', overflow: 'auto' }}
                >
                  <span
                    className="spinner-border spinner-border-sm text-secondary"
                    role="status"
                    aria-hidden="true"
                    style={{ width: '3rem', height: '3rem' }}
                  />
                  <span className="sr-only">Carregando registros...</span>
                </div>
                <div className="box-footer d-flex justify-content-between">
                  <button
                    type="button"
                    onClick={() => history.goBack()}
                    className="btn btn-default"
                  >
                    Voltar
                  </button>
                  <div>
                    <Link
                      to={`${process.env.PUBLIC_URL}/assinaturas/cadastrar`}
                      className="btn btn-primary pull-right"
                    >
                      Cadastrar
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
  if (loadingPagination) {
    return (
      <section className="mt-5">
        <div className="container-fluid">
          <div className="row d-flex">
            <div className="col-lg-12">
              <div className="card income p-3">
                <div className="d-flex align-items-center justify-content-between">
                  <h3 className="box-title mb-0 pb-0">Listar Assinaturas</h3>
                  <div className="d-flex align-items-center">
                    <div className="alert-secondary alert mb-0 mr-2">
                      Total de assinaturas:
                      {totalAssinaturas === 0 ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <strong>{totalAssinaturas}</strong>
                      )}
                    </div>
                    <div className="alert-warning alert mb-0">
                      Assinaturas duplicadas:
                      {totalDuplicado === 0 ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <strong>{totalDuplicado}</strong>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="text-center p-4 d-flex flex-column align-items-center justify-content-center"
                  style={{ height: 'calc(100vh - 450px)', overflow: 'auto' }}
                >
                  <span
                    className="spinner-border spinner-border-sm text-secondary"
                    role="status"
                    aria-hidden="true"
                    style={{ width: '3rem', height: '3rem' }}
                  />
                  <span className="sr-only">Carregando registros...</span>
                </div>
                <div className="d-flex align-items-center justify-content-end my-4">
                  <div className="d-flex align-items-center">
                    <label className="mb-0 mr-2">Página</label>
                    <input
                      className="form-control mx-2"
                      type="number"
                      value={page}
                      onChange={handleChangePage}
                      min={1}
                      max={totalPages}
                      disabled={loading}
                    />
                    <span className="mr-1">de</span>
                    <strong> {totalPages}</strong>
                    <button
                      className="btn btn-default ml-2"
                      disabled={page === 1 || loading}
                      onClick={handlePrevPage}
                      type="button"
                    >
                      Anterior
                    </button>
                    <button
                      className="btn btn-default"
                      onClick={handleNextPage}
                      disabled={loading}
                      type="button"
                    >
                      Próximo
                    </button>
                  </div>
                </div>
                <div className="box-footer d-flex justify-content-between">
                  <button
                    type="button"
                    onClick={() => history.goBack()}
                    className="btn btn-default"
                  >
                    Voltar
                  </button>
                  <div>
                    <Link
                      to={`${process.env.PUBLIC_URL}/assinaturas/cadastrar`}
                      className="btn btn-primary pull-right"
                    >
                      Cadastrar
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <>
      <section className="mt-5">
        <div className="container-fluid">
          <div className="row d-flex">
            <div className="col-lg-12">
              <div className="card income p-3">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <h3 className="box-title mb-0 pb-0">Listar Assinaturas</h3>
                  <div className="d-flex align-items-center">
                    <div className="alert-secondary alert mb-0 mr-2">
                      Total de assinaturas:
                      {totalAssinaturas === 0 ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <strong>{totalAssinaturas}</strong>
                      )}
                    </div>
                    <div className="alert-warning alert mb-0">
                      Assinaturas duplicadas:
                      {totalDuplicado === 0 ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <strong>{totalDuplicado}</strong>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="text-center"
                  style={{ height: 'calc(100vh - 450px)', overflow: 'auto' }}
                >
                  {assinaturas.length > 0 ? (
                    <table className="display table table-striped dataTable border">
                      <thead className="text-left">
                        <tr>
                          <th>ID</th>
                          <th>Nome</th>
                          <th>CPF</th>
                          <th colSpan={2} className="text-right">
                            Ações
                          </th>
                          {/*  <th>E-mail</th>
                           */}
                        </tr>
                      </thead>

                      <tbody>
                        {assinaturas.map(assinatura => (
                          <tr key={assinatura.Id} className="text-left">
                            <td>{assinatura.Id}</td>
                            <td>{assinatura.NmNome}</td>
                            <td>{assinatura.NmCpf}</td>
                            <td className="text-right">
                              <Link
                                to={`${process.env.PUBLIC_URL}/assinaturas/editar/${assinatura.Id}`}
                              >
                                <i className="fa fa-pencil text-info" />
                              </Link>
                              &nbsp;&nbsp;
                              <button
                                type="button"
                                className="border-0 bg-transparent outline-0 modalBtn"
                                onClick={() =>
                                  handleShow(assinatura.Id, assinatura.NmNome)
                                }
                              >
                                <i className="fa fa-trash text-danger" />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p className="px-2">
                      Sem assinaturas inseridas até o momento.
                    </p>
                  )}
                </div>

                <div className="d-flex align-items-center justify-content-end my-4">
                  <div className="d-flex align-items-center">
                    <label className="mb-0 mr-2">Página</label>
                    <input
                      className="form-control mx-2"
                      type="number"
                      value={page}
                      onChange={handleChangePage}
                      min={1}
                      max={totalPages}
                      disabled={loading}
                    />
                    <span className="mr-1">de</span>
                    <strong> {totalPages}</strong>
                    <button
                      className="btn btn-default ml-2"
                      disabled={page === 1 || loading}
                      onClick={handlePrevPage}
                      type="button"
                    >
                      Anterior
                    </button>
                    <button
                      className="btn btn-default"
                      onClick={handleNextPage}
                      disabled={loading}
                      type="button"
                    >
                      Próximo
                    </button>
                  </div>
                </div>
                <div className="box-footer d-flex justify-content-between">
                  <button
                    type="button"
                    onClick={() => history.goBack()}
                    className="btn btn-default"
                  >
                    Voltar
                  </button>
                  <div>
                    <button
                      className="btn btn-danger mr-2"
                      onClick={removerDuplicados}
                      disabled={totalDuplicado <= 0}
                      type="button"
                    >
                      Remover duplicados
                    </button>
                    <Link
                      to={`${process.env.PUBLIC_URL}/assinaturas/cadastrar`}
                      className="btn btn-primary pull-right"
                    >
                      Cadastrar
                    </Link>
                  </div>
                </div>

                {errorMessage && (
                  <div className="alert alert-danger mt-4">{errorMessage}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Apagar assinatura: {assinaturaName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b>ID:</b> <span className="id">{id}</span> <br />
          <b>Nome:</b> <span className="nome">{assinaturaName}</span> <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="danger" onClick={handleClickApagar}>
            Apagar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
