import React, { useState, useEffect, useRef } from 'react';
import { useField } from '@rocketseat/unform';
import PropTypes from 'prop-types';

export default function SelectInput({
  name,
  value,
  options,
  placeholder,
  className,
}) {
  const { registerField } = useField(name);

  const [selected, setSelected] = useState('');

  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      registerField({
        name,
        ref: ref.current,
        path: 'value',
      });
    }

    if (value) {
      setSelected(value);
    }
  }, [name, ref, registerField, value]);

  return (
    <select rest id={name} name={name} ref={ref} className={className}>
      <option selected disabled>
        {placeholder}
      </option>
      {options.map(option => (
        <>
          <option
            value={option.id}
            selected={selected === option.id && 'selected'}
          >
            {option.title}
          </option>
        </>
      ))}
    </select>
  );
}

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    map: PropTypes.func,
  }).isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

SelectInput.defaultProps = {
  placeholder: '',
  className: '',
};
