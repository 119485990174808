import React from 'react';
import PropsTypes from 'prop-types';

import SideNavbar from '~/components/SideNavbar';
import Header from '~/components/Header';
import Footer from '~/components/Footer';

export default function DefaultLayout({ children }) {
  return (
    <>
      <SideNavbar />
      <div className="page">
        <Header />
        {children}
        <Footer />
      </div>
    </>
  );
}

DefaultLayout.propTypes = {
  children: PropsTypes.element.isRequired,
};
