import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '~/styles/font-awesome.min.css';
import '~/styles/Fontastic.css';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';
import '~/styles/grasp_mobile_progress_circle-1.0.0.min.css';
import '~/styles/Style.css';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

  /* your styles go here */
  .sidenav-header-inner a,
  .brand-small a {
    color:#fff;
  }

  .side-navbar .sidenav-header {
      padding: 0px;
      padding-top: 20px;
      padding-bottom: 19px;
  }

  .side-navbar .sidenav-header h2 {
      margin: 5px 0 5px;
  }

  .outline-0{
    outline: none !important;
    box-shadow: none !important;
  }

  input:focus.input-material ~ label {
    font-size: 0.8rem;
    top: -10px;
    color: #33b35a;
  }

  .form-group-material span{
    color: #dc3545;
    font-size: 0.9em;
  }
`;
